import { groq } from 'next-sanity'
import { LocaleId } from '@portfolio/configs'
import { coalesceLocaleField } from '../helpers/coalesceLocaleField'
import { createLinkProjection } from '../projections/createLinkProjection'
import { createMediaProjection } from '../projections/createMediaProjection'
import { createTagsProjection } from '../projections/createTagsProjection'

export const createSectionFeaturedBlogPostsProjection = (
  localeId: LocaleId,
) => {
  const LINK_PROJECTION = createLinkProjection(localeId)
  const MEDIA_PROJECTION = createMediaProjection(localeId)
  const TAGS_PROJECTION = createTagsProjection(localeId)

  return groq`{
    _key,
    _type,
    ${coalesceLocaleField('title', localeId)},
    ${coalesceLocaleField('ctaLabel', localeId)},
    ctaLink${LINK_PROJECTION},
    featuredArticles[] -> {
      "id": _id,
      ${coalesceLocaleField('title', localeId)},
      "link": path.current,
      image${MEDIA_PROJECTION},
      tags[]->${TAGS_PROJECTION},
    }
  }
  `
}
