import { styled } from '@portfolio/styles'

const ChipItem = styled('div', {
  projectFont: 'ui04',
  borderRadius: '$rMax',
  display: 'inline-flex',
  padding: '$6 $12',
  textAlign: 'center',
  alignItems: 'center',
  justifyContent: 'center',
  whiteSpace: 'nowrap',
  lineHeight: '1.4',
  width: 'fit-content',
  cursor: 'pointer',
  transition: '0.2s color, 0.2s background',

  '&:focus-visible': {
    outline: '$focusBlue solid 3px;',
  },

  variants: {
    type: {
      dark: {
        color: '$black',
        border: '1px solid $black',
        '@lg': {
          '&:hover': {
            color: '$white',
            background: '$black',
          },
        },
      },
      darkDisabled: {
        color: '$black',
        border: '1px solid $black',
        cursor: 'default',
      },
      light: {
        color: '$white',
        border: '1px solid $white',
        '@lg': {
          '&:hover': {
            color: '$black',
            background: '$white',
          },
        },
      },
      lightDisabled: {
        color: '$white',
        border: '1px solid $white',
        cursor: 'default',
      },
    },
  },

  defaultVariants: {
    type: 'dark',
  },
})

type ChipProps = {
  title: string
  type?: 'dark' | 'darkDisabled' | 'light' | 'lightDisabled'
  disabled?: boolean
  onClick?: () => void
}

export const Chip = ({
  title,
  type = 'dark',
  disabled = false,
  onClick,
}: ChipProps) => (
  <ChipItem
    type={type}
    onClick={!disabled ? onClick : undefined}
    aria-disabled={disabled}
  >
    {title}
  </ChipItem>
)
